import React, { FunctionComponent } from "react";
import Helmet from "react-helmet";
import { withPrefix } from "gatsby";
import { useSiteMetadata } from "../hooks/useSiteMetadata";
import { Header, MenuItem } from "./header";
import { Footer } from "./footer";
import icon from "../../../static/logo.png";
import { usePagesList } from "../hooks/usePagesList";

const logo = <img className="w-auto h-15" src={icon} alt="Workflow" />;

const footer = {
  copyrightOwner: "Cartografías de la memoria",
  youtube: "https://www.youtube.com/channel/UCXWsc8JOPteLJSqb5vstj4A",
  facebook: "https://www.facebook.com/cartografiasmemoria/",
  instagram: "https://www.instagram.com/cartografias.memoria/",
};

export const Layout: FunctionComponent = ({ children }) => {
  const { title } = useSiteMetadata();
  const pagesList = usePagesList();
  const menuItems: MenuItem[] = [
    {
      label: "Inicio",
      href: "/",
    },
    ...pagesList.map((pageData) => ({
      label: pageData.frontmatter.title,
      href: pageData.fields.slug,
    })),
    {
      label: "Contacto",
      href: "/contacto",
    },
  ];
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#ffffff"
        />
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
        <meta name="theme-color" content="#fff" />
      </Helmet>
      <Header menuItems={menuItems} logo={logo} />
      <div className="min-h-screen">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">{children}</div>
      </div>
      <Footer {...footer} />
    </>
  );
};

export default Layout;
