import { graphql, useStaticQuery } from "gatsby";

interface PagesNode {
  id: string;
  fields: { slug: string };
  frontmatter: { title: string };
}

export const usePagesList = (): PagesNode[] => {
  const { pagesList } = useStaticQuery(
    graphql`
      query PAGES_LIST {
        pagesList: allMarkdownRemark(
          limit: 10
          sort: { fields: [frontmatter___publishedDate], order: DESC }
          filter: {
            frontmatter: {
              contentType: { eq: "paginas" }
              show_topbar: { eq: true }
            }
          }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  );
  return pagesList.edges.map(({ node }: { node: PagesNode }) => node);
};
